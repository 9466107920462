import { useCallback, useEffect, useState } from 'react';

import Button from 'components/Button';
import { MagicVoucherType } from 'components/MagicVouchersList/interface';
import TextField from 'components/TextField';
import Toast from 'components/Toast';
import { useAuth } from 'contexts/AuthUserContext';
import useCart from 'hooks/useCart';
import api from 'services/api';
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';

import * as S from './styles';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import ItemDetail from 'components/ItemDetail';
import useModal from 'hooks/useModal';
import { Modal } from 'components/Modal';

type MagicVoucherDetailModalProps = {
  onClose: () => void;
  voucher: any;
  canShare: boolean;
  onShare: (voucher: any) => void;
};

const moneyFormatter = (money: number) => '$ ' + (money / 100).toFixed(2);

const MagicVoucherDetailModal = ({
  onClose,
  voucher,
  canShare,
  onShare,
}: MagicVoucherDetailModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { authUser } = useAuth();
  const [showItemDetail, setShowItemDetail] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const { setVouchers, setCart, setMagicVoucherAnimation } = useCart();
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal();
  const router = useRouter();

  const handleOrderNow = () => {
    setIsLoading(true);
    router.push(`/merchants/${voucher.merchants[0]}`);
  };

  const renderItemDetail = (
    item: any,
    onClose: () => void,
    modalIsOpen: boolean
  ) => {
    return (
      <Modal widthAuto open={modalIsOpen} onClose={onClose}>
        <ItemDetail itemProp={item} close={onClose} find={true} />
      </Modal>
    );
  };

  const handleAddToCart = (item: any) => {
    openItemDetail(item);
  };

  const closeItemDetail = () => {
    setModalIsOpen(false);
    const main = document.querySelector('.mainwrapper') as HTMLElement;
    if (main) main.style.overflow = 'auto';
    onClose();
  };

  const openItemDetail = (item: any) => {
    item.price = 0;
    setCurrentItem(item);
    setModalIsOpen(true);
    setIsLoading(false);

    const main = document.querySelector('.mainwrapper') as HTMLElement;
    if (main) main.style.overflow = 'hidden';
  };

  useEffect(() => {
    if (!isLoading) setShowItemDetail(true);
  }, [currentItem]);

  return (
    <S.Wrapper>
      <S.CloseButton
        type="button"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => onClose()}
      >
        ✕
      </S.CloseButton>
      <S.Image>
        <S.InsideImage src="/img/voucher-bento-bag.svg" />
      </S.Image>

      <S.OverflowWrapper>
        <S.Container>
          <S.Title>{voucher.title}</S.Title>
          <S.Valid>
            Valid until {format(new Date(voucher.expiryDate), 'MMM do, yyyy')}
          </S.Valid>
        </S.Container>

        <S.Description>{voucher.description}</S.Description>

        {voucher.items?.length > 0 && (
          <S.ItemsWrapper>
            {voucher.items.map((item: any) => (
              <S.Item>
                <div>
                  <S.ItemName>{item.name}</S.ItemName>
                  <S.ItemPrice>{moneyFormatter(item?.price)}</S.ItemPrice>
                </div>
                <Button minimal onClick={() => handleAddToCart(item)}>
                  Add to cart
                </Button>
              </S.Item>
            ))}
          </S.ItemsWrapper>
        )}

        <S.QRContainer>
          <div>QR Code:</div>
          <QRCodeCanvas value={voucher.voucherId} />
        </S.QRContainer>
      </S.OverflowWrapper>

      <S.ButtonContainer>
        <Button loading={isLoading} onClick={handleOrderNow}>
          Order Now
        </Button>
      </S.ButtonContainer>

      {canShare && (
        <S.ButtonWrapper>
          <Button
            onClick={() => onShare(voucher)}
            disabled={isLoading}
            loading={isLoading}
          >
            Share
          </Button>
        </S.ButtonWrapper>
      )}
      {showItemDetail &&
        renderItemDetail(currentItem, closeItemDetail, modalIsOpen)}
    </S.Wrapper>
  );
};

export default MagicVoucherDetailModal;
